:root {
	--navy: rgb(0, 66, 88);
	--tco-blue: rgb(0, 158, 195);
	--white: rgb(255, 255, 255);
}
/* YouTube videos embeded through RTE instead of Video Component */
.mceNonEditable.embeditem {
	width: 100%;
	max-width: 70rem;
	margin: 0 auto;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.mceNonEditable.embeditem iframe {
	left: 0;
	top: 0;
	height: 100%;
	padding: 1rem;
	width: 100%;
	position: absolute;
}

.main-container {
	width: 100%;
	max-width: 70rem;
	margin: 0px auto 1rem;
	padding: 0 1rem;
}

.pdf-block {
	width: 100%;
	padding: 0 1rem;
}

.pdf-block .main-container {
	display: grid;
	grid-template-rows: auto 1fr;
	background-color: rgba(251, 251, 251, 1);
}

@media (min-width: 48em) {
	.main-container {
		margin-bottom: 2.5rem;
	}
}

.pdf-block h2 {
	margin-bottom: 1rem;
}

.pdf-block__img {
	object-fit: cover;
	aspect-ratio: 1 / 1.414;
	height: 240px;
	margin: 1rem auto 0;
}

.pdf-block__text-area {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem 0;
}

.pdf-block__link {
	display: flex;
	height: 44px;
	align-items: center;
	margin-right: auto;
	margin-top: 1rem;
	font-family: 'Brioni Sans Std', sans-serif;
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	letter-spacing: 0.3px;
	color: var(--tco-blue);
}

.pdf-block__link:hover {
	text-decoration: underline;
}

.pdf-block__link::before {
	content: '';
	display: block;
	height: 1.5rem;
	width: 1.5rem;
	margin-right: 0.5rem;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url('data:image/svg+xml,<svg width="37" height="37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.583 3.083H9.25a3.083 3.083 0 0 0-3.083 3.084v24.666a3.083 3.083 0 0 0 3.083 3.084h18.5a3.083 3.083 0 0 0 3.083-3.084v-18.5l-9.25-9.25Z" stroke="%23009EC3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M21.583 3.083v9.25h9.25M24.667 20.042H12.333M24.667 26.208H12.333M15.417 13.875h-3.084" stroke="%23009EC3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/> strokeLinecap="round"strokeLinejoin="round"/> <path d="M15.417 13.875h-3.084" stroke="%23009EC3"/></svg>');
}

@media (min-width: 48em) {
	.pdf-block {
		padding: 0;
		background-color: rgba(251, 251, 251, 1);
	}

	.pdf-block .main-container {
		grid-template-columns: auto 1fr;
	}

	.pdf-block__img {
		height: 420px;
		width: calc(420px / 1.414);
		object-fit: cover;
		margin: 0;
		padding: 0;
	}

	.pdf-block__text-area {
		padding: 1rem 0 1rem 2.5rem;
	}
}

@media (min-width: 70em) {
	.pdf-block__text-area {
		padding: 1rem 0 1rem 5rem;
	}
}

/* Kanslisidan */

.filter-section {
	display: flex;
	margin: 2rem 0;
	padding: 2rem 0;
}

.filter-section > div {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'title'
		'filters'
		'reset';
}

@media (min-width: 678px) {
	.filter-section > div {
		grid-template-areas:
			'title reset'
			'filters filters';
	}
}

.filter-section__title {
	grid-area: title;
	margin-bottom: 0.75rem;
}

.filter-section__reset {
	grid-area: reset;
}

.toggle-button {
	display: flex;
	justify-content: left;
	align-items: center;
	transition: all 0.2s ease 0s;
	outline: none;
	width: 100%;
	height: 3rem;
	background-color: rgb(255, 255, 255);
	color: var(--navy);
	padding: 1rem 0;
	font-size: 1rem;
}

.toggle-button::before {
	content: '';
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 1.25rem;
	border: 0.125rem solid var(--tco-blue);
	background-color: var(--white);
	border-radius: 0.25rem;
	transition:
		background-color 0.2s ease,
		border 0.2s ease;
}

.toggle-button:hover::before {
	border-color: var(--navy);
}

.toggle-button--active::before {
	background-image: url('data:image/svg+xml,<svg width="9" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 3 2.5 2.5L8 1" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-repeat: no-repeat;
	background-position: center;
	border-color: var(--navy);
	background-color: var(--navy);
}

@media (min-width: 678px) {
	.toggle-button {
		box-shadow: var(--navy) 0px 0px 0px 2px inset;
		width: auto;
	}

	.toggle-button::before {
		display: none;
	}

	.toggle-button--active {
		background-color: var(--navy);
		color: var(--white);
	}
}

.filter-section__filters {
	grid-area: filters;
	position: relative;
	width: 100%;
	cursor: pointer;
	transition: box-shadow 0.3s ease;
}

.filter-section__open-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	line-height: 1.4rem;

	padding: 1rem 1.1875rem;

	background: var(--white);
	box-shadow: inset 0 0 0 0.125rem var(--navy);
	border-radius: 0.125rem;
	margin-bottom: 0.25rem;
}

.filter-section--open .filter-section__open-button,
.filter-section__open-button--active {
	box-shadow: inset 0 0 0 0.1875rem var(--tco-blue);
}

.filter-section__open-button::after {
	content: '';
	width: 0.9375rem;
	height: 0.5rem;
	background-image: url('data:image/svg+xml,<svg width="15" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.5 1 6 6 6-6" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-repeat: no-repeat;
	background-position: center;
}

.filter-section--open .filter-section__open-button::after {
	rotate: 180deg;
}

.filter-section__list {
	display: none;
	flex-wrap: wrap;
	gap: 0.5rem;
	border: 0.1875rem solid var(--tco-blue);
	background-color: var(--white);
	padding: 0.5rem 1rem 1rem;
}

.filter-section__list > span {
	width: 100%;
	background-color: var(--navy);
	padding: 0.5rem;
	color: var(--white);
	text-align: center;
	font-size: 1.125rem;
	margin-top: 0.5rem;
	transition: background-color 0.2s ease;
}

.filter-section__list > span:hover {
	background-color: var(--tco-blue);
}

.filter-section--open .filter-section__list {
	display: flex;
}

@media (min-width: 678px) {
	.filter-section__filters {
		display: flex;
		box-shadow: none;
		padding: 0;
	}
	.filter-section__open-button {
		display: none;
	}

	.filter-section__list {
		display: flex;
		border: none;
		padding: 0;
		background-color: transparent;
	}

	.filter-section__list > span {
		display: none;
	}

	.toggle-button {
		padding: 1rem 1.5rem;
		font-size: 1.25rem;
	}
}
